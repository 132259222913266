<template>
  <div class="footer">
    <div class="container">
      <div class="row">
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-4">
          <a href="/x372" class="logo"><img src="https://file.xssbdc.com/static/website/bottomLogo.png" alt=""></a>
        </div>
        <div class="col-md-2 col-sm-8 hidden-xs">
          <h6>服务项目</h6>
          <ul>
            <li>
              <router-link to="/business">公司业务</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-sm-8 hidden-xs">
          <h6>新闻资讯</h6>
          <ul>

            <li>
              <router-link to="/news">行业动态</router-link>
            </li>

          </ul>
        </div>
        <div class="col-md-4 col-sm-12">
          <h6>联系我们吧！</h6>
          <a href="" class="btn"><i class="fa fa-phone"></i> 热线：023-67456888</a>
        </div>
      </div>
      <div class="row">
      </div>
      <div class="row">
        <div class="col-sm-12">
          <hr>
        </div>
        <div class="col-sm-12 hidden-xs">
          <div class="link"><a  target='_blank'> Copyright &copy; 2020-2025
            德润消费大数据科技（重庆）有限公司</a></div>
          <a target="_blank" href='https://beian.miit.gov.cn/'
             style="display:inline-block;text-decoration:none;height:20px;line-height:20px; margin-top:10px"> <img
              src="https://file.xssbdc.com/static/website/beian.png" style="float:left;"/>
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">渝公网安备
              50010502002710号</p></a>
        </div>
      </div>
    </div>
    <!-- <div style="width:300px;margin:0 auto; padding:20px 0;">
      <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"> <img src="https://file.xssbdc.com/static/website/beian.png" style="float:left;"/> <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">渝公网安备 50010502002710号</p ></a >
    </div> -->
  </div>
</template>

<script>
export default {
  name: "tfooter"
}
</script>

<style scoped>
.btn {
  background-color: #5656e4;
}


</style>