let addAnimationStyle=(Anim,cls, dura, delay, index)=> {
  document.getElementsByClassName(cls)[index].style.animation = Anim;
  document.getElementsByClassName(cls)[index].style["animation-duration"] =
      dura + "s";
  document.getElementsByClassName(cls)[index].style["animation-delay"] =
      delay + "s";
  document.getElementsByClassName(cls)[index].style["animation-fill-mode"] =
      "forwards";
}
export {
  addAnimationStyle
}
