<template>
  <div class="new-con-box">
    <!-- 导航栏-->
    <Nav nav="business" @getScrollTop="getScrollTop"></Nav>
    <!--    菜单-->
    <sideMenu></sideMenu>
    <!--  内容-->
    <div class="container-box">
      <!--    banner-->
      <div class="banner-box">
        <img src="https://file.xssbdc.com/static/website/banner6.png" alt="">
        <div>
          <span class="banner-text-title">公司业务与精准服务</span>
          <span class="banner-text-title-en">COMPANY BUSINESS AND PRECUSION SERVICES</span>
        </div>

      </div>
      <!--      文案-->
      <div class="de-bigData container">
        <div class="title animate__animated">小微企业个人   科技助贷</div>
        <div class="info animate__animated">
          德润消费大数据专注于为广大小微企业主、个体工商户和消费者提供金融服务，公司集合了传统金融的管理技术和互联网的思维模式，开发了经纪人大数据服务系统、贷投狗小程序、app等、渠道录件系统等多个项目，力图解决金融服务市场普遍存在的规模小、效率低、技术手段落后、风控系统落后等弱点，能更好地为消费者提供优质专业的金融服务，致力于金融科技助贷、住房公积金综合服务、置换贷款、交易赎楼、尾款垫付、不良资产处置等多项板块的融资需求。
        </div>
<!--        <div class="info animate__animated">-->
<!--          业务提供技术支持，满足公司的信息化建设需求。-->
<!--        </div>-->
      </div>
      <!--      业务-->
      <div class="yewu-box">
        <div class="yewu-title">公司业务</div>
        <!--          小屏头-->
        <div class="sm-menu-yw">
          <div class="sm-menu-ye-item" :class="{'active-menu':type==1}" @click="changMenu(1)">担保安居分</div>
          <div class="sm-menu-ye-item" :class="{'active-menu':type==2}" @click="changMenu(2)">乐业保</div>
          <div class="sm-menu-ye-item" :class="{'active-menu':type==3}" @click="changMenu(3)">不良资产处置</div>
          <div class="sm-menu-ye-item" :class="{'active-menu':type==4}" @click="changMenu(4)">法拍房</div>
        </div>
        <div class="content-yw">
          <!--          大屏头-->
          <div class="menu-yw">
            <div class="menu-ye-item" :class="{'active-menu':type==1}" @click="changMenu(1)">担保安居分</div>
            <div class="menu-ye-item" :class="{'active-menu':type==2}" @click="changMenu(2)">乐业保</div>
            <div class="menu-ye-item" :class="{'active-menu':type==3}" @click="changMenu(3)">不良资产处置</div>
            <div class="menu-ye-item" :class="{'active-menu':type==4}" @click="changMenu(4)">法拍房</div>
          </div>
          <div v-show="type==1" class="yew-con">
            <div class="yew-con-tit">担保安居分</div>
            <div class="cp-text">
              <div class="cp-t">产品介绍：</div>
              <div class="cp-c">担保安居分是专为客户量身定制的额度充裕、还款灵活、尺度要求宽松的房抵贷产品。</div>
            </div>
            <div class="cp-text">
              <div class="cp-t">产品特色：</div>
              <div class="cp-c">按揭房抵押房再贷款、征信要求低、低利息、有房就可贷
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t">贷款额度：</div>
              <div class="cp-c">5-90万
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t">贷款期限：</div>
              <div class="cp-c">3年期
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t">还款方式：</div>
              <div class="cp-c">先息后本、中途不归本</div>
            </div>
            <div class="cp-text">
              <div class="cp-t">担保方式：</div>
              <div class="cp-c">重庆主城、1小时经济圈区县名下有银行按揭/抵押、全款房产的客户
              </div>
            </div>
          </div>
          <div v-show="type==2"  class="yew-con">
            <div class="yew-con-tit">乐业保</div>
            <div class="cp-text">
              <div class="cp-t">产品介绍：</div>
              <div class="cp-c">一款由担保有限责任公司对借款人资质进行审批后，并向银行为客户提供担保服务，从而使银行向客户放款的金融产品。</div>
            </div>
            <div class="cp-text">
              <div class="cp-t">产品特色：</div>
              <div class="cp-c">额度充足、手续简单、尺度宽松、一站式通道快速办理
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t">贷款额度：</div>
              <div class="cp-c">5-90万
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t">贷款期限：</div>
              <div class="cp-c">5年期
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t">还款方式：</div>
              <div class="cp-c">等额本息</div>
            </div>
            <div class="cp-text">
              <div class="cp-t">担保方式：</div>
              <div class="cp-c">抵押房/按揭房，房龄是2002年内的即可（无还款时间、还款方式的限制）
              </div>
            </div>
          </div>
          <div v-show="type==3"  class="yew-con">
            <div class="yew-con-tit">不良资产处置</div>
            <div class="cp-text">
              <div class="cp-t">产品定义：</div>
              <div class="cp-c">是指通过综合运用法律法规允许范围内的一切手段和方法，对资产进行的价值变现和价值提升。</div>
            </div>
            <div class="cp-text">
              <div class="cp-t">处置资产范围：</div>
              <div class="cp-c">股权类资产、债权类资产和实物类资产
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t">资产处置方式：</div>
              <div class="cp-c">1、破产清算、拍卖、招标、协议转让、折扣变现等方式
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t"></div>
              <div class="cp-c">2、债转股、债务重组、诉讼及诉讼保全、以资抵债、资产置换、企业重组、实物资产再投资完善、实物资产出租、资产重组、实物资产投资等方式
              </div>
            </div>

          </div>
          <div v-show="type==4"  class="yew-con">
            <div class="yew-con-tit">法拍房</div>
            <div class="cp-text">
              <div class="cp-t">产品特色：</div>
              <div class="cp-c">特价房源、专享房源、避暑房源
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t"></div>
              <div class="cp-c">司法房产拍卖辅助服务平台</div>
            </div>

            <div class="cp-text">
              <div class="cp-t">产品内容：</div>
              <div class="cp-c">咨询｜看房｜参拍｜贷款 等
              </div>
            </div>
            <div class="cp-text">
              <div class="cp-t"></div>
              <div class="cp-c">房源覆盖全重庆市各区县</div>
            </div>
            <div class="cp-text">
              <div class="cp-t"></div>
              <div class="cp-c">起拍价低于市场价25%以下</div>
            </div>
          </div>
          <div v-show="type==1"  class="tu-box">
            <img src="https://file.xssbdc.com/static/website/tu1.png" class="tu" alt="">
          </div>
          <div v-show="type==2"  class="tu-box">
            <img src="https://file.xssbdc.com/static/website/tu2.png" class="tu" alt="">
          </div>
          <div v-show="type==3"  class="tu-box">
            <img src="https://file.xssbdc.com/static/website/tu3.png" class="tu" alt="">
          </div>
          <div v-show="type==4"  class="tu-box">
            <img src="https://file.xssbdc.com/static/website/tu.png" class="tu" alt="">
          </div>
        </div>
      </div>
      <!--      业务流程-->
      <div class="ywlc">
        <div class="gs-titl">业务流程</div>
        <div class="gs-titl-en">OPERATION FLOW</div>
        <div class="container lc-con">
          <div class="row">
            <div class="ywlc-box">
              <div class="col-md-2 col-sm-4 lc-item">
                <img src="https://file.xssbdc.com/static/website/dianhua1.png" alt="">
                <div class="lc-text">拨打电话</div>
              </div>
              <div class="col-md-2 col-sm-4 lc-back-item">
                <img src="https://file.xssbdc.com/static/website/dianhua3.png" class="dianhua3" alt="">
                <div class="lc-back-item-tit">拨打电话</div>
                <div class="lc-back-item-text">拨打德润客服电:
                  674568888 告知客服人员您的基本情况与贷款需求，并按客服人员的提示开始准备您的贷款资料。
                </div>
              </div>
            </div>
            <div class="col-md-3 jiantou-box">
              <img src="https://file.xssbdc.com/static/website/jiantou.png" alt="">
            </div>
            <div class="ywlc-box">
              <div class="col-md-2  col-sm-4 lc-item">
                <img src="https://file.xssbdc.com/static/website/shenqing.png" alt="">
                <div class="lc-text">填写申请</div>
              </div>
              <div class="col-md-2 col-sm-4 lc-back-item">
                <img src="https://file.xssbdc.com/static/website/shengqing1.png" class="dianhua3" alt="">
                <div class="lc-back-item-tit">填写申请</div>
                <div class="lc-back-item-text">房贷经理上门进行现场评估，辅导您填写贷款申请表并收集相关贷款资料。</div>
              </div>
            </div>
            <div class="col-md-3 jiantou-box">
              <img src="https://file.xssbdc.com/static/website/jiantou.png" alt="">
            </div>
            <div class="ywlc-box">
              <div class="col-md-2 col-sm-4  lc-item">
                <img src="https://file.xssbdc.com/static/website/hetong.png" alt="">
                <div class="lc-text">签署合同</div>
              </div>
              <div class="col-md-2 col-sm-4 lc-back-item">
                <img src="https://file.xssbdc.com/static/website/hetong1.png" class="dianhua3" alt="">
                <div class="lc-back-item-tit">签署合同</div>
                <div class="lc-back-item-text">贷款资料齐备后出具评估结果客服人员预约您前往就近服务网点签约。签约完成后，资金即可到账。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    新闻资讯-->
    <new-info></new-info>
    <!--      脚标-->
    <tfooter></tfooter>
  </div>
</template>

<script>
import Nav from '../components/nav';
import sideMenu from '../components/side-menu';
import tfooter from "@/components/footer";
import newInfo from "../components/new-info";
import {addAnimationStyle} from '../js/AnimationStyle';

export default {
  name: "business",
  components: {Nav, sideMenu, tfooter, newInfo},
  data(){
    return{
      type:1, //产品类型
    }
  },
  mounted() {
    this.type=this.$route.query.type || 1;
    this.$nextTick(() => {
      setTimeout(() => {
        this.animatuonContent();
      }, 1000);
      window.$(".ywlc-box").hover(
          function () {
            window.$(this).children('.lc-item').css("display", "none");
            window.$(this).children(".lc-back-item").css("display", "flex");
          },
          function () {
            window.$(this).children('.lc-item').css("display", "block");
            window.$(this).children(".lc-back-item").css("display", "none");
          }
      );
    })
  },
  methods: {
    //奇幻产品类型
    changMenu(type) {
      this.type=type;
    },
    getScrollTop(e) {
      // console.log('wz:', e)
      this.scrollTop = e;
      if (this.scrollTop > 300 && this.scrollTop < 1100) {
        this.animatuonContent();
      }
    },
    //介绍内容动画
    animatuonContent() {
      addAnimationStyle('flipInY', "title", 1, 0, 0);
      addAnimationStyle('shakeY', "info", 1, 0, 0);
      // addAnimationStyle('shakeY', "info", 1, 0, 1);
    },
  },
}
</script>

<style scoped lang="less">
.banner-box {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner-text-title {
      font-size: 76px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 66px;
    }

    .banner-text-title-en {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

}

//文案
.de-bigData {
  text-align: center;
  margin: 130px auto;

  .title {
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: 55px;
    opacity: 1;
  }

  .info {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
    opacity: 1;
  }


}

.yewu-box {
  width: 100%;
  background-image: url("https://file.xssbdc.com/static/website/bg3.png");
  padding: 80px 0 0 10%;
  margin-bottom: 140px;
  background-size: cover;

  .row {
    margin: 0;
  }

  .yewu-title {
    margin-bottom: 90px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
  }

  .content-yw {
    width: 100%;
    background-color: #fff;
    height: 1000px;
    display: flex;
    //align-items: center;
    .menu-yw {
      padding: 0;
      height: 100%;
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .menu-ye-item {
        background-color: #6F6F6F;
        color: #fff;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }


      .menu-ye-item:nth-child(2n) {
        background-color: #808080;
      }

    }

    .yew-con {
      flex: 1;
      padding: 10% 10%;
      width: 100%;
      height: 100%;

      .yew-con-tit {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10%;
      }

      .cp-text {
        display: flex;
        align-items: flex-start;
        margin-bottom: 40px;
        width: 70%;

        .cp-t {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          width: 150px;
        }

        .cp-c {
          font-weight: normal;
          line-height: 25px;
          flex: 1;
        }
      }
    }

    .tu-box {
      width: 25%;
      height: 100%;

      .tu {
        width: 100%;
        height: 100%;

      }
    }

  }

}
.active-menu{
  background-color: #5656E4 !important;
}

//小品头
.sm-menu-yw {
  display: none;
  align-items: center;
  justify-content: center;

  .sm-menu-ye-item {
    background-color: #6F6F6F;
    color: #fff;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    flex: 1;
    height: 70px;
    line-height: 70px;
  }

  .sm-menu-ye-item:nth-child(2n) {
    background-color: #808080;
  }
}

.lc-con {
  margin-bottom: 130px;

  .lc-item {
    text-align: center;
    height: 280px;
    margin-bottom: 15px;
    //background-color: #42b983;
  }

  .lc-back-item {
    background-color: #5656E4;
    border-radius: 10px;
    display: none;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 18px 15px 20px;
    margin-bottom: 15px;

    .lc-back-item-tit {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      margin: 18px 0 20px;
    }

    .lc-back-item-text {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .lc-text {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: 35px;
  }

  .jiantou-box {
    text-align: center;
    padding-top: 5%;
  }
}

.ywlc{
  text-align: center;
}
.gs-titl {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 30px;
}

.gs-titl-en {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-bottom: 108px;
}


</style>