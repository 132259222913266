<template>
  <div class="section section-news hidden-xs">
    <div class="container" style="position: relative;">
      <div class="section-title text-center">
        <h3>新闻资讯</h3>
        <small>结合设计经验与营销实践，提供有价值的互联网资讯</small>
      </div>
      <div class="row">
        <div class="col-sm-4" v-for="(item,index) in list" :key="index ">
          <div class="new-tit">{{item.title}}</div>
          <router-link :to="`/new-details?id=${item.id}`"  class="news-item">
           <div class="content-new" v-html="item.content"></div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import url from "../js/config";
export default {
  name: "new-info",
  data(){
    return{
      list:[],
    }
  },
  mounted() {
    this.getNewList();
  },
  methods:{
    //获取列表
    getNewList(){
      this.$axios.get(url+'/business/websiteNews/newsForUccn',{params:{pageNo:1,classify: 0}}).then((res)=>{
        if(res.data.code==200){
          let total=res.data.data.total;
          let arr=res.data.data.records || [];
          if(arr.length){
            if(total<4){
              this.list=arr;
            }else {
              this.list=arr.slice(0,3);
              console.log(this.list)
            }
          }
        }else {
          this.$message.warning(res.data.message)
        }

      })
    },
  }
}
</script>

<style scoped >
.section-news {
  background: url('https://file.xssbdc.com/static/website/1622449973.jpg') no-repeat center center;
  -moz-background-size: cover;
  background-size: cover;
  color: #fff;
  position: relative;
}
.new-tit{
  margin: 0 0 20px;
  line-height: 40px;
  height: 40px;
  overflow: hidden;
  font-size: 16px;
  text-decoration:none;
}
.content-new{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

</style>