<template>
    <div class="nav-con">
      <div :class=" ['nav','container']" v-if="scrollTop < 1000">
        <div   class="nav-box row">
          <router-link to="/" class="col-md-4">
            <img v-if="scrollTop<1000" src="https://file.xssbdc.com/static/website/logo.png" class="logo" alt="">
            <img v-else src="https://file.xssbdc.com/static/website/logo1.png" class="logo" alt="">
          </router-link>
          <!--      <div class="nav-list" >-->
          <div class="col-md-2">
            <router-link to="/" :class="['nav-item',[nav=='index' ?scrollTop > 1000 ?'active-nav-cha':'active-nav' :''],{'nav-list-cha':scrollTop>1000}]">首页</router-link>
          </div>
          <div class="col-md-2">
            <router-link to="/business" :class="['nav-item',[nav=='business' ? scrollTop>1000?'active-nav-cha':'active-nav':''],{'nav-list-cha':scrollTop>1000}]">公司业务</router-link>
          </div>
          <div class="col-md-2">
            <router-link to="/news" :class="['nav-item',[nav=='news' ? scrollTop>1000?'active-nav-cha':'active-nav':''],{'nav-list-cha':scrollTop>1000}]">新闻资讯</router-link>
          </div>
          <div class="col-md-2">
            <router-link to="/about-us" :class="['nav-item',[nav=='aboutUs' ? scrollTop>1000?'active-nav-cha':'active-nav':''],{'nav-list-cha':scrollTop>1000}]">关于我们</router-link>
          </div>
          <!--      </div>-->
        </div>
      </div>
  <!--    小屏头-->
      <div class="xs-head">
        <div class="head-box">
          <img src="https://file.xssbdc.com/static/website/logo1.png" class="logo" alt="">
          <i @click="changMenu" class="fa fa-bars"></i>
        </div>
        <ul  v-show="isOpenMenu">
          <li class="xs-head-item">
            <router-link to="/index">首页</router-link>
          </li>
          <li class="xs-head-item">
            <router-link to="/business">公司业务</router-link>
          </li>
          <li class="xs-head-item">
            <router-link to="/news">新闻资讯</router-link>
          </li>
          <li class="xs-head-item">
            <router-link to="/about-us">关于我们</router-link>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    nav: String,  //导航类型
  },
  data() {
    return {
      scrollTop: 0,//滚动位置
      isOpenMenu:false,//是否展开菜单
    }
  },
  watch:{
    $route(){
      this.changMenu();
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);

  },
  methods: {
    //展开菜单
    changMenu(){
      this.isOpenMenu= !this.isOpenMenu
    },
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log( '位置：', window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)
      this.$emit('scrollTop', this.scrollTop);
    },
  },

}
</script>

<style scoped lang="less">

.nav {
  .nav-box {
    width: 1200px;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    margin: 0 auto;

    .logo {
      width: 167px;
      height: 35px;
    }

    //.nav-list {
    //  display: flex;
    .nav-item {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 30px;
      //margin: 0 42px;
      text-decoration: none;
      color: #FFFFFF;
    }

    .nav-list-cha {
      color: #000;
    }

    .active-nav {
      border-bottom: 2px solid #fff;
    }

    .active-nav-cha {
      border-bottom: 2px solid #000;
    }

    //}

  }

  position: fixed;
  top: 35px;
  left: 0;
  right: 0;
  z-index: 20;
}
.nav-con{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}


//小屏头
.xs-head {
  background-color: #fff;
  display: none;
  padding: 0 15px;
  .head-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
  }
  .xs-head-item{
    height: 40px;
    border-bottom: 1px solid #e9e9e9;
    list-style:none;
    line-height: 40px;
    a{
      color: #000;
      text-decoration: none;
    }
  }

}


</style>
<style>
.fa{
  font-size: 26px;
}
</style>