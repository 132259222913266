<template>
  <div class="cndns-right hidden-xs">
    <div class="cndns-right-meau meau-code">
      <a href="javascript:" class="cndns-right-btn"> <span><img src="https://file.xssbdc.com/static/website/xcx.png" alt=""></span>
        <p>小程序</p>
      </a>
      <div class="cndns-right-box">
        <div class="box-border">
          <div class="sev-t"><img src="https://file.xssbdc.com/static/website/xcx.jpg" alt="二维码" /><i>微信小程序扫码关注</i></div>
        </div>
      </div>
    </div>
    <div class="cndns-right-meau meau-contact">
      <a href="javascript:" class="cndns-right-btn"> <span><img src="https://file.xssbdc.com/static/website/dh.png" alt=""></span>
        <p>咨询热线</p>
      </a>
      <div class="cndns-right-box">
        <div class="box-border">
          <div class="sev-t clearfix"> <span class="fa fa-phone-square"></span>
            <p>023-67456888<br />
              <i>全天候技术服务热线</i></p>
          </div>
        </div>
      </div>
    </div>
    <div class="cndns-right-meau meau-code">
      <a href="javascript:" class="cndns-right-btn"> <span><img src="https://file.xssbdc.com/static/website/xz.png" alt=""></span>
        <p>APP下载</p>
      </a>
      <div class="cndns-right-box">
        <div class="box-border">
          <div class="sev-t"><img src="https://file.xssbdc.com/static/website/800600.jpg" alt="二维码" /><i>扫码下载手机APP</i></div>
        </div>
      </div>
    </div>
    <div class="cndns-right-meau meau-top" id="top-back">
      <a href="javascript:" class="cndns-right-btn"> <span><img src="https://file.xssbdc.com/static/website/jt.png" alt=""></span> <i>返回顶部</i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "sideMenu",
  mounted() {
    this.$nextTick(()=>{
      window.$("#top-back").hide();
      window.$('#top-back').on('click', function () {
        window.$('body,html').animate({scrollTop: 0}, 300);
      });
      window.$(window).scroll(function () {
        if (window.$(this).scrollTop() > 350) {
          window.$("#top-back").fadeIn();
        } else {
          window.$("#top-back").fadeOut();
        }
      });
    })
  }
}
</script>

<style scoped>

</style>